require('../scss/layout.scss');

/*UIkit.notification({
    message: 'Clientes, clients, <br /> <br />Ré-ouverture de la Renaissance Cagny Mardi 2 Juin ' +
        'nous mettons en place un service de plateaux repas à emporter sur réservation. <br /><br />' +
        'Il suffit juste de nous appeler dans la matinée (Avant 11h' +
        'au 06.16.66.85.02 ou au 02.31.39.24.29',
    status: 'danger',
    pos: 'top-center',
    timeout: 100000,
});*/
